::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #f4f0ff;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #0b9b70;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0b9b70;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

body {
  overflow-x: hidden;
}
